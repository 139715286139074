import storage from '@/utils/storage'

// 意向评分
export const scoreOptions = () => {
  const score = storage.get('baseData')['leads_score'].map(item => {
    return  {'label': `${item.key} (${item.value})`, 'value': item.value}
  })
  return score
}
 
// 跟进形式
export const modalityOptions = () => {
  const modality = storage.get('baseData')['follow_from'].map(item => {
    return  {'label': item.key, 'value': item.value}
  })
  return modality
}

// 是否到店
export const istoshopOptions = () => {
  const modality = storage.get('baseData')['is_to_shop'].map(item => {
    return  {'label': item.key, 'value': item.value}
  })
  return modality
}

