<!--
 * @Author: your name
 * @Date: 2021-05-27 17:31:10
 * @LastEditTime: 2021-06-02 11:05:23
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \04eos-app\src\pages\newCreateForm\createSuccess\index.vue
-->
<template>
  <App
    title="Follow up successfully"
    :style="appStyle"
  >
    <div class="createSuccess">
      <div class="success-icon">
        <Icon
          name="passed"
          size="56"
          color="#07C160"
        ></Icon>
        <h1 class="success-text">Follow up successfully</h1>
      </div>
      <Button
        class="success-btn"
        @click="fullowupback"
      >Back</Button>
    </div>
  </App>
</template>

<script>
import { Icon, Button } from 'vant'
import './index.less'
export default {
  components: {
    Icon,
    Button
  },
  data () {
    return {
    }
  },
  computed: {
    appStyle () {
      return {
        'background-color': this.bgColor
      }
    }
  },
  methods: {
    fullowupback () {
      this.$router.go(-1)
    }
  }
}
</script>

<style>
</style>