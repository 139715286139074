<template>
  <div>
    <App
      :title="
        chooseProfile
          ? 'customer-profile'
          : chooseCloseLost
          ? 'Reason of Closed lost'
          : 'Leads Follow-up'
      "
      @click-left="onNavBarClickLeft"
      :left-arrow="!chooseProfile && !chooseCloseLost"
      v-if="isshow"
    >
      <Tabs
        v-model="currTab"
        title-active-color="#323233"
        class="tabs full-container"
        @change="changeTabs"
      >
        <Tab
          v-for="$e in tabDataList"
          :key="$e.label"
          :name="$e.label"
          :title="$e.title"
          :disabled="$e.disabled"
        >
          <DynamicForm
            ref="form"
            v-model="formData"
            :rules="rules"
            :fieldData="fieldData"
            :name="name"
          >
          </DynamicForm>
          <!-- <Field
            :value="
              (profileData && profileData.length) || detailCprofile
                ? '已选择'
                : ''
            "
            label-width="120"
            label="Customer Profile"
            readonly
            clickable
            @click="chooseProfile = true"
            input-align="right"
            placeholder="Please select"
            right-icon="arrow"
          /> -->
        </Tab>
      </Tabs>
      <div :style="chooseProfile ? 'display:block' : 'display:none'">
        <CustomerProfile
          @on-cancel="cancelProfile"
          @on-confirm="confirmProfile"
          :detailCprofile="detailCprofile"
          :remark="remark"
          :data="customerprofileList"
          :show="chooseProfile"
        ></CustomerProfile>
      </div>
      <div v-if="chooseCloseLost">
        <CloseLost
          class="close-lost"
          @on-cancel="cancelCloseLost"
          @on-confirm="confirmCloseLost"
          :data="closeLostData"
          :show="chooseCloseLost"
        ></CloseLost>
      </div>
      <FooterBtn
        cancelText="Back"
        okText="Submit"
        @cancel="onNavBarClickLeft"
        @ok="onNavBarClickRight"
      ></FooterBtn>
    </App>
    <div v-else>
      <FollowupScuess />
    </div>
  </div>
</template>
 
<script>
import { Tab, Tabs, Toast, Field } from "vant";
import DynamicForm from "_c/dynamicForm";
import FooterBtn from "_c/footerBtn/index";
import { modalityOptions, scoreOptions, istoshopOptions } from "./attr.js";
import { getList, getDetail, clueFollow } from "@/api/chargequery";
import { customerprofile } from "@/api/common";
// import Success from '@/pages/success'
import FollowupScuess from "./followupScuess";
import CustomerProfile from "_c/customer-profile/index";
import CloseLost from "./close-lost/index";
import dayjs from "dayjs";
import "vant/lib/popup/style";
import "vant/lib/picker/style";
import "./index.less";

export default {
  name: "cluesFollowUpFrom",
  components: {
    Tab,
    Tabs,
    DynamicForm,
    CustomerProfile,
    CloseLost,
    FollowupScuess,
    FooterBtn,
    // Field,
  },
  data() {
    return {
      currTab: "",
      setopition: [],
      tabDataList: [
        { title: "Open", label: "OPEN", disabled: false },
        // { title: '待联系', label: 'ATTEMPTING_TOUCHING', disabled: false },
        { title: "Following", label: "IN_TOUCHING", disabled: false },
        {
          title: "Test drive scheduled",
          label: "TD_SCHEDULED",
          disabled: false,
        },
        {
          title: "Failed to contact",
          label: "CLOSE_NO_TOUCHING",
          disabled: false,
        },
        { title: "Closed lost", label: "CLOSELOST", disabled: false },
        { title: "Converted", label: "CONVERTED", disabled: false },
      ],
      formData: {
        profile: "",
        reason: "",
        cprofile: "",
        followupForm: "",
        score: "",
      },
      typea: "00",
      isshow: true,
      ischoose: true,
      num: 0,
      add: [],
      rules: {
        score: [
          { required: true, message: 'Please select a rating.' }
        ],
        followupForm: [
          { required: true, message: 'Please select a follow-up method.' }
        ],
        campaignCode: [
          { required: true, message: 'Please select a campaign.' }
        ],
        followupMessage: [
          { required: true, message: 'Please write down the follow-up details.' }
        ],
        nextFollowupTime: [
          { required: true, message: 'Please select the next follow-up date.' }
        ],
        reason: [
          { required: true, message: 'Please describe the reason.' }
        ],
        isToshop: [
          { required: true, message: 'Please select if customer visited the outlet.' }
        ],
        // TPreason: [
        //   { required: true, message: 'Please select' }
        // ]
      },
      fieldData: [],
      intentionscore: true,
      name: "",
      chooseProfile: false,
      profileData: [],
      chooseCloseLost: false,
      closeLostData: {
        list: [],
        reason: "",
      },
      customerprofileList: [],
      detailCprofile: [], // 跟进详情中的之前的cp记录
      remark: "",
      detailStatus: "",
    };
  },

  watch: {
    "formData.followupForm": {
      handler(e) {
        this.fieldData.forEach((item) => {
          if (item.title === "Follow-up") {
            item.data.forEach((el) => {
              if (el.label === "活动信息") {
                if (e == 4) {
                  el.visible = true;
                } else {
                  el.visible = false;
                }
              }
            });
          }
        });
      },
    },
    immediate: true,
    deep: true,
  },
  computed: {
    sourceData() {
      return [
        {
          title: this.intentionscore ? "Intent" : false, //
          data: [
            {
              label: "Rating",
              key: "score",
              type: "select",
              options: scoreOptions(),
              visible: this.intentionscore,
            }, //
          ],
        },
        {
          title: "Follow-up",
          data: [
            {
              label: "Follow-up Method",
              key: "followupForm",
              type: "select",
              options: modalityOptions(),
            },
            {
              label: "活动信息",
              key: "campaignCode",
              type: "select",
              options: this.add,
              visible: this.typea === "10",
            }, //
            {
              label: "Follow-up Details",
              key: "followupMessage",
              direction: "column",
              props: {
                placeholder: "Please write",
                type: "textarea",
                border: true,
                maxLength: 1000,
              },
            },
            {
              label: "Next Follow-up Date",
              key: "nextFollowupTime",
              type: "calendar",
              attrs: { "min-date": new Date() },
            },
          ],
        },
        {
          title: this.currTab === "CONVERTED" ? "" : "Customer Profile",
          data: [
            {
              label: "If Visited",
              key: "isToshop",
              type: "select",
              options: istoshopOptions(),
              visible: this.isShop,
            },
            // { label: 'Customer Profile', key: 'cprofile', props: { placeholder: 'Please select', clickable: true, readonly: true, 'right-icon': 'arrow' }, listeners: { click: this.profileShow } },
          ],
        },
      ];
    },
  },
  methods: {
    // 返回
    onNavBarClickLeft() {
      this.$router.go(-1);
    },
    // 提交跟进
    onNavBarClickRight() {
      this.$refs["form"][0].validate().then(async (valid) => {
        if (valid) {
          /* Toast.loading({
            message: "Loading...",
            forbidClick: true,
            loadingType: "spinner",
            overlay: true,
          }); */
          const data = this.formatData(this.formData);
          const res = await clueFollow(data);
          Toast.clear();
          if (res.code === "000000") {
            this.isshow = false;
          } else {
            Toast(res.msg);
          }
        }
      });
    },
    changeTabs(name) {
      this.tabChangeDesable(this.detailStatus);
      this.formData = {};
      this.stateCode = name;
      if (this.detail.score) this.formData.score = this.detail.score;
      if (
        name === "CLOSELOST" ||
        name === "CONVERTED" ||
        name === "CLOSE_NO_TOUCHING" ||
        name === "TD_SCHEDULED"
      ) {
        this.intentionscore = false;
      } else {
        this.intentionscore = true;
      }
      this.init();
      if (name === "CLOSE_NO_TOUCHING") {
        this.fieldData.unshift({
          title: "",
          data: [
            {
              label: "Reason",
              key: "reason",
              direction: "column",
              props: {
                placeholder: "Please write down the detailed reason",
                type: "textarea",
                border: true,
                maxLength: 200,
              },
            },
          ],
        });
      }
      if (name === "CLOSELOST") {
        this.fieldData.unshift({
          title: "",
          data: [
            {
              label: "Reason of Closed lost",
              key: "TPreason",
              props: {
                placeholder: "Please select",
                clickable: true,
                readonly: true,
                "right-icon": "arrow",
                maxLength: 200,
              },
              listeners: { click: this.lostShow },
            },
          ],
        });
        this.rules["TPreason"] = [{ required: true, message: "Please select" }];
      }
      if (name === "CONVERTED") {
        this.fieldData.unshift({
          title: "",
          data: [
            {
              label: "Reason of Manual Conversion",
              key: "reason",
              direction: "column",
              props: {
                placeholder: "Please write",
                type: "textarea",
                border: true,
                maxLength: 200,
              },
            },
          ],
        });
      }
    },
    // 获取活动信息
    async getList() {
      const res = await getList();
      if (res.code === "000000") {
        if (res.data.records) {
          this.add = res.data.records.map((item) => {
            return { label: item.name, value: item.code };
          });
        }
        this.init();
        // return res.data.records
      } else {
        Toast(res.msg);
      }
    },
    // 处理意向模块展示
    handleshow() {
      if (
        this.name === "CLOSE_NO_TOUCHING" ||
        this.name === "CLOSELOST" ||
        this.name === "CONVERTED"
      ) {
        return true;
      }
    },
    // 获取 Customer Profile 主数据
    getCustomerprofile() {
      return customerprofile().then((res) => {
        if (res.success) {
          this.customerprofileList = JSON.parse(res.data.template).map(
            (item) => {
              return {
                ...item,
                options: item.trees.map((it) => {
                  return {
                    label: it.name,
                    value: it.code,
                  };
                }),
              };
            }
          );
        }
      });
    },
    // Customer Profile
    profileShow() {
      this.chooseProfile = true;
    },
    confirmProfile(data) {
      this.profileData = Object.keys(data)
        .map((el) => {
          return data[el];
        })
        .toString()
        .split(",");
      this.remark = data.remark;
    },
    cancelProfile() {
      this.chooseProfile = false;
    },
    // 暂败原因
    lostShow() {
      this.chooseCloseLost = true;
    },
    cancelCloseLost() {
      this.chooseCloseLost = false;
    },
    confirmCloseLost(data) {
      this.formData.reason = data.reason;
      this.closeLostData = data;
      this.formData = Object.assign(this.formData, {
        closeLostReason: data.list,
        reason: data.reason,
      });
      // 修改选择项
      this.fieldData.forEach((item) => {
        item.data.forEach((el) => {
          if (el.props.label === "Reason of Closed lost") {
            if (data.list.length > 0) {
              this.formData.TPreason = `已选择${data.list.length}项`;
              el.props.placeholder = `已选择${data.list.length}项`;
            } else {
              this.formData.TPreason = "";
              el.props.placeholder = "Please select";
            }
          }
        });
      });
    },
    init() {
      this.fieldData = JSON.parse(JSON.stringify(this.sourceData));
      this.fieldData[1].data[3].attrs["min-date"] = new Date();
      // this.fieldData[2].data[1].listeners = { click: this.profileShow }
    },
    async getData() {
      await this.getList();
      await this.getDetail();
      this.detail.istoshop ? (this.isShop = false) : (this.isShop = true);
      this.Accprofile = JSON.parse(this.detail.cprofile);
      this.sourceData.forEach((item) => {
        if (item.title === "客户信息") {
          item.data.forEach((el) => {
            if (el.label === "是否到店") {
              el.visible = this.isShop;
            }
          });
        }
      });
      this.stateFunc(this.detail.status);
    },
    // 获取线索详情
    async getDetail() {
      Toast.loading({
        message: "Loading...",
        forbidClick: true,
        loadingType: "spinner",
        overlay: true,
      });
      const params = {
        id: this.$route.query.eosLeadsId,
      };
      const res = await getDetail(params);
      Toast.clear();
      if (res.code === "000000") {
        this.detail = res.data;
        this.detailCprofile = JSON.parse(res.data.cprofile);
        this.detailStatus = res.data.status;
        this.remark = res.data.remark;
        if (this.detail.status === "ATTEMPTING_TOUCHING") {
          this.detail.status = "IN_TOUCHING";
          this.stateCode = this.detail.status;
        } else {
          this.stateCode = this.detail.status;
        }
      } else {
        Toast(res.msg);
      }
    },
    formatData(data) {
      const res = {};
      // 信息
      res.closeLostReason = data.closeLostReason;
      if (this.stateCode === "CLOSELOST") {
        // 暂败原因
        if (data.closeLostReason.includes("9")) {
          data.closeLostReason.push(data.reason);
        }
      } else if (this.stateCode === "CLOSE_NO_TOUCHING") {
        // 无法联系原因
        res.reason = data.reason;
      } else if (this.stateCode === "CONVERTED") {
        // 已转化原因
        res.reason = data.convertedReason;
      }
      // 无法联系原因
      if (this.stateCode === "CLOSE_NO_TOUCHING") {
        res.reason = data.reason;
      }
      if (this.stateCode === "CONVERTED") {
        // 已转化原因
        res.reason = data.reason;
      }
      // CP
      if (this.profileData) {
        const profile = this.customerprofileList.map((item) => {
          return {
            name: item.name,
            code: item.code,
            type: item.type,
            trees: item.trees.filter((t) => this.profileData.includes(t.code)),
          };
        });
        res.cprofile = profile;
      }
      // 评分
      res.score = data.score;
      // 跟进形式
      res.followupForm = data.followupForm;
      // 跟进信息
      res.followupMessage = data.followupMessage;
      // 活动code
      // if (data.campaignCode) {
      //   res.campaignCode = data.campaignCode // campaignCode
      // }
      // 下次跟进时间
      if (data.nextFollowupTime) {
        res.nextFollowupTime = dayjs(data.nextFollowupTime).format(
          "YYYY-MM-DD"
        );
      }
      // 备注信息
      if (this.remark) {
        res.remark = this.remark;
      }
      // 是否到店
      data.isToshop ? (res.isToshop = data.isToshop) : (res.isToshop = true);
      // status
      res.status = this.stateCode;
      // 线索ID
      res.leadsId = this.detail.eosLeadsId;
      // ownerUserId
      res.ownerUserId = this.detail.eosOwnerUserId;
      res.level2Code = "002";
      return res;
    },

    stateFunc(state) {
      /**
       * 1、如果是“Open”或”已转化"状态，线索是无法跟进的，线索列表等不提供跟进入口；
        3、如果是"已预约试驾"状态，进入跟进页面就显示“已预约试驾”状态；在已预约试驾状态下进行跟进，可选“暂败”及“已转化”；
        4、如果是“待联系”状态，进入跟进页面默认选中“跟进中”状态，同时跟进实际情况可手工换成“无法联系”、“暂败”、“已转化”；
        5、如果是“跟进中”、“暂败”、“无法联系”状态，进入跟进页面默认原状态，销售根据实际情况可手工换成“跟进中”、“暂败”、“已转化”；
       */
      if (
        state === "TD_SCHEDULED" ||
        state === "IN_TOUCHING" ||
        state === "CLOSELOST" ||
        state === "CLOSE_NO_TOUCHING"
      ) {
        this.currTab = state;
      }
      if (state === "ATTEMPTING_TOUCHING") {
        this.currTab = "IN_TOUCHING";
      }
      this.tabChangeDesable(state);
      this.changeTabs(this.currTab);
    },
    // 处理当前状态对应的可选状态有那些
    tabChangeDesable(state) {
      this.tabDataList.forEach((item) => {
        if (state === "TD_SCHEDULED") {
          if (item.label === "CLOSELOST" || item.label === "CONVERTED") {
            item["disabled"] = false;
          } else {
            item["disabled"] = true;
          }
        }
        if (
          state === "IN_TOUCHING" ||
          state === "CLOSELOST" ||
          state === "CLOSE_NO_TOUCHING"
        ) {
          if (
            item.label === "IN_TOUCHING" ||
            item.label === "CLOSELOST" ||
            item.label === "CONVERTED"
          ) {
            item["disabled"] = false;
          } else {
            item["disabled"] = true;
          }
        }
        if (state === "ATTEMPTING_TOUCHING") {
          if (
            item.label === "CLOSE_NO_TOUCHING" ||
            item.label === "CLOSELOST" ||
            item.label === "CONVERTED"
          ) {
            item["disabled"] = false;
          } else {
            item["disabled"] = true;
          }
        }
      });
    },
    changeDisable(item) {
      const status = this.detail.status;
      switch (item.value) {
        case "OPEN":
          return true;
        case "ATTEMPTING_TOUCHING":
          return true;
        case "IN_TOUCHING":
          return !["ATTEMPTING_TOUCHING", "IN_TOUCHING", "CLOSELOST"].includes(
            status
          ); // 'ATTEMPTING_TOUCHING'
        case "TD_SCHEDULED":
          return status !== "TD_SCHEDULED";
        case "CLOSE_NO_TOUCHING":
          return ![
            "ATTEMPTING_TOUCHING",
            "CLOSE_NO_TOUCHING",
            "IN_TOUCHING",
          ].includes(status);
        case "CLOSELOST":
          return ![
            "TD_SCHEDULED",
            "ATTEMPTING_TOUCHING",
            "IN_TOUCHING",
            "CLOSELOST",
          ].includes(status);
        case "CONVERTED":
          return ![
            "TD_SCHEDULED",
            "ATTEMPTING_TOUCHING",
            "IN_TOUCHING",
            "CONVERTED",
            "CLOSELOST",
          ].includes(status);
      }
    },
  },
  mounted() {
    this.getData();
    this.getCustomerprofile();
  },
};
</script>
