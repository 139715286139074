<template>
  <!-- <App 
      title="暂败原因"
      left-arrow
      @click-left="onNavBarClickLeft"
      right-text="确定"
      @click-right="onNavBarClickRight"
    > -->
  <div class="cp-box">
    <CellGroup>
      <Field label=""
             label-width="100%"
             class="option">
        <template #input>
          <div class="labels">
            <button type="button"
                    @click="changeData($i)"
                    v-for="$i in datalist"
                    :key="$i.key"
                    :class="$i.active? 'on' : ''">
              {{$i.label}}
            </button>
          </div>
        </template>
      </Field>
      <Field v-if="selectData.list.includes('9')"
             v-model="selectData.reason"
             type="textarea"
             label=""
             label-width="100%"
             placeholder="Please describe the reason."
             class="reason" />
    </CellGroup>
    <FooterBtn @cancel="onNavBarClickLeft"
               @ok="onNavBarClickRight"
        cancelText="Back"
        okText="Submit"></FooterBtn>
  </div>

  <!-- </App> -->
</template>

<script>
import { CellGroup, Field, Toast } from 'vant'
import FooterBtn from '_c/footerBtn/index'
import 'vant/lib/cell-group/style'
import 'vant/lib/field/style'
import './index.less';

export default {
  name: 'closeLost',
  components: { CellGroup, Field, FooterBtn },
  props: {
    data: {
      type: Object,
      default () {
        return {
          list: [],
          reason: ''
        }
      }
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    show: {
      handler (value) {
        if (value) {
          this.changeData()
        }
      },
      deep: true
    },
    'selectData.reason': {
      handler (e) {
        if (e.length > 200) {
          Toast('长度不得超过200')
          this.selectData.reason = ''
        }
      }
    }
  },
  data () {
    return {
      datalist: [
      ],
      selectData: {
        list: [],
        reason: ''
      }
    }
  },
  methods: {
    onNavBarClickLeft () {
      this.$emit('on-cancel')
    },
    onNavBarClickRight () {
      this.$emit('on-confirm', this.selectData)
      this.$emit('on-cancel')
    },
    changeData (item) {
      if (item && item.key) {
        if (this.selectData.list.includes(item.key)) {
          this.selectData.list.splice(this.selectData.list.indexOf(item.key), 1)
          item.active = false
        } else {
          this.selectData.list.push(item.key)
          item.active = true
        }
      }
      this.datalist.forEach((e) => {
        if (this.selectData.list.includes(e.key)) e.active = true
      })
    },
  },
  created () {
    this.selectData = JSON.parse(JSON.stringify(this.data))
    this.datalist = this.$storage.get('baseData')['close_lost_reason'].map(item => {
      return { 'label': item.key, 'key': item.value, 'active': false }
    })
    this.changeData()
  }
}
</script>
